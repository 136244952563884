<template>
    <div class="tab-content" >
       <AdvChart securityType="EQUITY" :selectedSymbol="selectedSymbol" key="equityChart"  ref="advChart" />
		
    </div>

	<AttributionFooter authors='quoteMedia' />
</template>

<script>
import { mapState } from 'vuex';
import SecurityFormatter from '../../common/SecurityFormatter';

import AdvChart from '../charts/highcharts/AdvChart';
import AttributionFooter from '../footer/AttributionFooter.vue';


export default {
    name: "Charting",
    data() {
		return {
			justMounted: false,
			active: false,
			
		}
	},
    components : {
        AdvChart,
		//Chart,
		AttributionFooter
    },
    computed: {
        ...mapState(['selectedEquity']),
		securityId() {
			return this.$store.state.selectedEquity.securityId;
		},
        selectedSymbolForTv() {
			let exchangeSymbol = this.$store.state.selectedEquity.exchangeSymbol;
			let symbol = this.$store.state.selectedEquity.symbol;
			if( exchangeSymbol == 'XNAS') {
				return "NASDAQ:" + symbol
			}
			else if( exchangeSymbol == 'XNYS') {
				return "NYSE:" + symbol
			}
			else if( exchangeSymbol == 'OOTC') {
				return "OTC:" + symbol
			}
			else if( exchangeSymbol == 'XTSE') {
				return "TSX:" + symbol
			}
			else if( exchangeSymbol == 'XTSX') {
				return "TSXV:" + symbol
			}
			else if( exchangeSymbol == 'XCNQ') {
				return "CSE:" + symbol
			}
			else {
				return symbol;
			}
			
		},
    
			selectedSymbol() {
					return SecurityFormatter.getSymbolViaCdnExchanges(this.$store.state.selectedEquity);
			},
		
    },
    watch: {
		selectedEquity() {
			console.debug(this.$options.name + " selected equity watcher called");
		
		}
	},
    mounted() {
		
		this.justMounted = true;
		console.debug(this.$options.name + " mounted");
	},
	activated() {
		this.active = true;
		console.debug(this.$options.name + " activated");
		
		this.justMounted = false;
	},
	deactivated() {
		this.justMounted = false;
		this.active = false;
        console.debug(this.$options.name + " dectivated");
	},
    methods: {
        
    }
}
</script>
